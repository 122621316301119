import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "react-static"
    }}>{`React Static`}</h1>
    <p>{`In order to use MDX with `}<a parentName="p" {...{
        "href": "http://react-static.js.org"
      }}>{`React Static`}</a>{` you just need to `}<a parentName="p" {...{
        "href": "https://react-static.js.org/docs/config/#webpack"
      }}>{`configure the `}<inlineCode parentName="a">{`webpack`}</inlineCode>{` option`}</a>{` in `}<inlineCode parentName="p">{`static.config.js`}</inlineCode>{`.`}</p>
    <p>{`First, scaffold a new React Static site:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`react-static create myapp
yarn add @mdx-js/loader@next
`}</code></pre>
    <p>{`Then add the following to the `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` field to your `}<inlineCode parentName="p">{`node.api.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export default () => ({
  webpack: config => {
    config.module.rules.map(rule => {
      if (
        typeof rule.test !== 'undefined' ||
        typeof rule.oneOf === 'undefined'
      ) {
        return rule
      }

      rule.oneOf.unshift({
        test: /.mdx$/,
        use: ['babel-loader', '@mdx-js/loader']
      })

      return rule
    })

    return config
  }
})
`}</code></pre>
    <p>{`Finally, add an `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` file anywhere in the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory.
It “Just Works” when you import it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`# My first MDX Page

some awesome content
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      